import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import BorderedBox from '../components/borderedbox'
import SecondaryNav from '../components/secondarynav'

// Images
import headerimage from '../assets/images/careers/Corprate_US_Internships_03.jpg'
import mbheaderimage from '../assets/images/careers/Corprate_CareersMB-Header_03.jpg'
import icon1 from '../assets/images/careers/Careers_US_Internship_Icon_07.jpg'
import icon2 from '../assets/images/careers/Careers_US_Internship_Icon_05.jpg'
import icon3 from '../assets/images/careers/Careers_US_Internship_Icon_08.jpg'
import icon4 from '../assets/images/careers/Careers_US_Internship_Icon_09.jpg'
import usintern1 from '../assets/images/careers/Careers_US_Internship_16.jpg'
import usintern2 from '../assets/images/careers/Careers_US_Internship_18.jpg'
import usintern3 from '../assets/images/careers/Careers_US_Internship_20.jpg'
import usintern4 from '../assets/images/careers/Careers_US_Internship_22.jpg'

import { trackPageData, getPageDetails } from '../analytics'

class UsInternship extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="US Internships | The Children's Place Corporate Website"
          description="Looking for an internship with The Children's Place in the US? Learn more about who we're looking for and how to apply on The Children's Place Corporate Website."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-2">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="MAKE OUR PLACE YOURS"
            />
            {/* <p class="text-center">We’re looking for rising college seniors full of energy and intellect that are ready to work and learn. Interns at The Children’s Place get to explore the retail industry, work with inspirational leaders and build on their skill set through a 10-week corporate paid internship.</p> */}
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="US Internships"
          name1="Careers"
          link1="/careers"
          name2="US Internships"
          link2="/us-internships"
          name3="Hong Kong Internships"
          link3="/hong-kong-internships"
        />

        <div class="row mb-5 text-center">
          <div class="col-12">
            {/* <a target="_blank" href="https://childrensplace.wd1.myworkdayjobs.com/en-US/TCP01/details/Corporate-Internship-Summer-2023--All-Majors-_R000024213?q=internship">
              <p class="alert alert-primary" role="alert">
                Click here to apply to the 2022 Internship program.
              </p>
            </a> */}
            <p class="text-large">
              We believe that diverse teams when managed in an inclusive
              environment ‘outperform’ others. Our vision is to foster a culture
              in which inclusion, equity, and diversity are a part of our
              identity. A culture in which employees KNOW they are represented,
              FEEL their voices will be heard, and TRUST that we will all work
              together to provide and sustain these conditions.
            </p>
            <br />
            <p class="text-large">
              We’re looking for rising college seniors full of energy and
              intellect that are ready to work and learn. Interns at The
              Children’s Place get to explore the retail industry, work with
              inspirational leaders and build on their skill set through a
              10-week corporate paid internship.
            </p>
            <p class="text-large">
              Interns will get hands-on experience, learn from formal training
              sessions, participate in community service and present a group
              project to the leadership team at the end of the program. Want in?
            </p>
            {/* <p>
                        <Link to="#">
                            <button type="button" class="btn btn-outline-primary">APPLY FOR US INTERNSHIPS</button>
                        </Link>
                    </p> */}
          </div>
        </div>

        <div class="row mb-5 text-center">
          <div class="col-lg-3 col-6">
            <img src={icon1} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>10 weeks</strong>
            </h5>
            <p>
              The amount of time you’ll be working 1-on-1 with us at our PLACE{' '}
            </p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon2} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>$20/hour</strong>
            </h5>
            <p>Because everyone should get paid to work!</p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon3} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>36 hours/week</strong>
            </h5>
            <p>To work hard, learn tons & make your mark</p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon4} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>Summer Fridays</strong>
            </h5>
            <p>
              When the clock strikes 1:00pm head out to enjoy the summer sun!
            </p>
          </div>
        </div>

        {/* Key Areas */}
        <div class="row mb-5">
          <div class="col-12">
            <hr class="d-md-none border-top" />
            <BorderedBox classname="mb-n-border">
              <h2>FIND YOUR PLACE IN ONE OF OUR KEY AREAS</h2>
              <ul>
                <div class="row text-left">
                  <div class="col-lg-6 col-md-12">
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Believe in fashion, form, function & fun?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Collaborate with <strong class="primary">Design </strong>
                      (from concept to collection) creating on-trend products
                      that parents and kids love.
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Do you spot trends before they get big?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Develop the right mix of clothing and accessories with{' '}
                      <strong class="primary">Merchandising</strong> to
                      influence the next season’s coolest trends.
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Are you a digital mastermind?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Strategize with{' '}
                      <strong class="primary">
                        E-commerce & Digital Marketing
                      </strong>{' '}
                      to provide customers with a seamless (and fun!) online
                      experience.
                    </li>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Do numbers & finance thrill you?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Get into the game with{' '}
                      <strong class="primary">Finance</strong> and help drive
                      our competitive business results!
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Always looking for more efficient ways to do things?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Team up with{' '}
                      <strong class="primary">
                        Global Sourcing & Logistics
                      </strong>{' '}
                      to manage our product supply chain from factory to
                      customer.
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Do you love organization & planning
                        <br class="d-md-none" />
                      </strong>{' '}
                      Ensure customers can get the product they love whenever
                      and wherever they want with{' '}
                      <strong class="primary">Planning & Allocation</strong>.{' '}
                    </li>
                  </div>
                </div>
              </ul>
            </BorderedBox>
          </div>
        </div>
        {/* End Key Areas */}

        <div class="row mb-5 text-center">
          <div class="col-12">
            <h2>
              ADDITIONAL <br class="d-md-none" />
              EVERYDAY PERKS
            </h2>
          </div>
          <div class="col-lg-3 col-6">
            <img src={usintern1} className="img-fluid" alt="" />
            <p>
              <small>
                <strong>
                  Free shuttle to the train station & a bus stop in front of our
                  building!
                </strong>
              </small>
            </p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={usintern2} className="img-fluid" alt="" />
            <p>
              <small>
                <strong>
                  Dozens of restaurants & stores in walking distance.
                </strong>
              </small>
            </p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={usintern3} className="img-fluid" alt="" />
            <p>
              <small>
                <strong>
                  3,100 square foot cafeteria & coffee bar with free WiFi.
                </strong>
              </small>
            </p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={usintern4} className="img-fluid" alt="" />
            <p>
              <small>
                <strong>
                  On-site mock store to see each season come to life.
                </strong>
              </small>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default UsInternship
